<template>
  <div class="form-delivery">
    <div class="title">{{ $t('newcustomer.delivery_details') }}</div>
    <div class="title-description">{{ $t('Optional') }}</div>
    <!-- delivery or customer instruction -->
    <div class="pl-1">
      <DeliveryInstructionArea
        @deliveryInstruction="getDeliveryInstruction"
        :instruction="delivery_instruction"
        :idModal="'addAndEdit'"
      />
    </div>

    <!-- default time -->
    <div>
      <div class="d-flex align-center pl-4 delivery-time">
        <ion-text>{{ $t('newcustomer.set_default_time') }}</ion-text>
      </div>
      <ion-row>
        <ion-col size="6" class="ion-no-padding">
          <ion-item @click="setOpenTime" id="popover-datetime-open" class="itemss">
            <ion-text>{{ $t('newcustomer.open_time') }}</ion-text>
            <ion-text>{{ formatTime(defaultOpenTime) }}</ion-text>
          </ion-item>
          <ion-datetime
            class="def-time"
            mode="ios"
            v-if="openTimeRef"
            hour-cycle="h23"
            presentation="time"
            :value="defaultOpenTime"
            v-model="defaultOpenTime"
            @ionChange="(ev) => (defaultOpenTime = ev.detail.value)"
            @click="setOpenTime"
          />
        </ion-col>
        <ion-col size="6" class="ion-no-padding pl-2">
          <ion-item
            @click="setCloseTime"
            id="popover-datetime-close"
            :class="['itemss', errorDefaultTime ? 'item-danger' : '']"
          >
            <ion-text :color="errorDefaultTime ? 'red' : ''">{{ $t('newcustomer.close_time') }}</ion-text>
            <ion-text :color="errorDefaultTime ? 'red' : ''">{{ formatTime(defaultCloseTime) }}</ion-text>
          </ion-item>
          <ion-datetime
            class="def-time"
            mode="ios"
            v-if="closeTimeRef"
            hour-cycle="h23"
            presentation="time"
            :value="defaultCloseTime"
            v-model="defaultCloseTime"
            @ionChange="(ev) => (defaultCloseTime = ev.detail.value)"
            @click="setCloseTime"
          />
          <ion-text v-if="errorDefaultTime" class="fs-1" color="danger">{{
            $t('errorDefaultTime')
          }}</ion-text>
        </ion-col>
      </ion-row>
    </div>

    <!-- delivery days -->
    <div class="pl-4">
      <div class="d-flex align-center delivery-day">
        <ion-text>{{ $t('newcustomer.set_delivery_days') }}</ion-text>
      </div>
      <div class="d-flex align-center">
        <ion-text>{{ $t('newcustomer.days') }}</ion-text>
      </div>

      <!-- list day of week -->
      <div v-for="workingHour in currentWorkingHours" :key="workingHour.id" @click="closeDefTime">
        <div class="form-checkbox">
          <ion-checkbox
            v-model="workingHour.active"
            :value="workingHour.active"
            slot="start"
            @ionChange="() => onChangeHourActive(workingHour)"
          >
          </ion-checkbox>
          <div class="label">
            <ion-label>{{ workingHour.day_name }}</ion-label>
          </div>
        </div>

        <ion-row>
          <!-- open time -->
          <ion-col size="6" class="">
            <ion-item
              :disabled="!workingHour.active"
              :id="`popover-datetime-open` + workingHour.id"
              :class="['items', 'item-' + getColorValidate(workingHour.active, workingHour.validOpenTime)]"
            >
              <ion-text
                position="stacked"
                :color="getColorValidate(workingHour.active, workingHour.validOpenTime)"
              >
                {{ $t('newcustomer.open_time') }}
              </ion-text>
              <ion-text>{{ formatTime(workingHour.openTime) }}</ion-text>

              <ion-popover
                mode="ios"
                :trigger="`popover-datetime-open` + workingHour.id"
                :show-backdrop="false"
                trigger-action="click"
                class="popover-time"
              >
                <ion-datetime
                  mode="ios"
                  hour-cycle="h23"
                  presentation="time"
                  :disabled="!workingHour.active"
                  :name="'open-' + workingHour.day_name"
                  :value="workingHour.openTime"
                  v-model="workingHour.openTime"
                  @click="onChooseTime($event, defaultOpenTime)"
                  @ionCancel="onCancelDatetime"
                  @ionChange="() => onChangeOpenCloseTime(workingHour)"
                />
              </ion-popover>
            </ion-item>
          </ion-col>
          <!-- close time -->
          <ion-col size="6">
            <ion-item
              :disabled="!workingHour.active"
              :id="`popover-datetime-close` + workingHour.id"
              :class="['items', 'item-' + getColorValidate(workingHour.active, workingHour.validCloseTime)]"
            >
              <ion-text position="stacked" :class="workingHour.validCloseTime ? 'text-danger' : ''">
                {{ $t('newcustomer.close_time') }}
              </ion-text>
              <ion-text :class="workingHour.validCloseTime ? 'text-danger' : ''">{{
                formatTime(workingHour.closeTime)
              }}</ion-text>
              <ion-popover
                mode="ios"
                :trigger="`popover-datetime-close` + workingHour.id"
                :show-backdrop="false"
                trigger-action="click"
                class="popover-time"
              >
                <ion-datetime
                  mode="ios"
                  hour-cycle="h23"
                  presentation="time"
                  :disabled="!workingHour.active"
                  :name="'close-' + workingHour.day_name"
                  :value="workingHour.closeTime"
                  v-model="workingHour.closeTime"
                  @click="onChooseTime($event, defaultCloseTime)"
                  @ionCancel="onCancelDatetime"
                  @ionChange="() => onChangeOpenCloseTime(workingHour)"
                />
              </ion-popover>
            </ion-item>
          </ion-col>
        </ion-row>

        <!-- error message -->
        <ion-row v-if="workingHour.active">
          <ion-col size="6" class="pr-2">
            <ion-text v-if="workingHour.validOpenTime" class="fs-1 text-danger">
              <div class="pr-1">{{ workingHour.validOpenTime }}</div>
            </ion-text>
          </ion-col>
          <ion-col size="6" class="pl-2">
            <ion-text v-if="workingHour.validCloseTime" class="fs-1 text-danger">
              <div class="pr-1">{{ workingHour.validCloseTime }}</div>
            </ion-text>
          </ion-col>
        </ion-row>
      </div>
    </div>

    <div class="form-btn">
      <ion-button
        mode="md"
        :class="['ml-4', isEdit ? '' : 'ml-auto']"
        :disabled="errorDefaultTime"
        @click="saveHours"
      >
        {{ $t('save') }}
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonDatetime, IonPopover } from '@ionic/vue';
import dayjs from 'dayjs';
import { calendarOutline, timeOutline } from 'ionicons/icons';
import { pick } from 'lodash';
import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  name: 'HoursForm',
  components: {
    IonDatetime,
    IonPopover,
    DeliveryInstructionArea: defineAsyncComponent(() =>
      import('@/modules/sale/components/AddAndEditDeliveryInstruction/DeliveryInstructionArea.vue')
    )
  },
  emits: ['save-hours', 'failed', 'skip'],
  props: {
    isEdit: {
      default: true
    },
    workingHours: {
      type: Object,
      default() {
        return {};
      }
    },
    defaultWorkingHours: {
      type: Array,
      default() {
        return [];
      }
    },
    openTimeProps: {
      type: String,
      default: ''
    },
    closeTimeProps: {
      type: String,
      default: ''
    },
    workingHoursProps: {
      type: Array,
      default() {
        return [];
      }
    },
    deliveryInstruction: {
      type: String,
      default: '',
      required: true
    }
  },
  data(props) {
    return {
      defaultTime: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      defaultOpenTime: '',
      defaultCloseTime: '',
      defaultDatetime: null,
      prevDatetime: null,
      currentWorkingHours: [],
      defaultTimeValid: null,
      delivery_instruction: props.deliveryInstruction,
      openTimeRef: false,
      closeTimeRef: false
    };
  },
  setup() {
    return {
      timeOutline,
      calendarOutline,
      dayjs
    };
  },
  mounted() {
    this.defaultOpenTime = this.defaultTime;
    this.defaultCloseTime = this.defaultTime;
    this.handlegetDeliveryHours();
  },
  computed: {
    formatTime() {
      return (time) => {
        if (!time) return '00:00';
        return dayjs(time).format('HH:mm');
      };
    },
    errorDefaultTime() {
      return this.defaultOpenTime > this.defaultCloseTime ? true : false;
    }
  },
  methods: {
    handlegetDeliveryHours() {
      if (this.workingHours && this.workingHours.length) {
        this.currentWorkingHours = [];
        this.workingHours.forEach((workingDay) => {
          this.currentWorkingHours.push({
            ...workingDay,
            openTime: workingDay.active ? this.getDateTime(workingDay.open_hour, workingDay.open_minute) : '',
            closeTime: workingDay.active
              ? this.getDateTime(workingDay.close_hour, workingDay.close_minute)
              : '',
            validOpenTime: '',
            validCloseTime: ''
          });
        });
      } else {
        try {
          if (this.workingHoursProps.length !== 0) {
            this.defaultOpenTime = this.openTimeProps;
            this.defaultCloseTime = this.closeTimeProps;
            this.currentWorkingHours = this.workingHoursProps;
          } else {
            this.setEmptyWorkingHours();
          }
        } catch {
          this.setEmptyWorkingHours();
        }
      }
      this.defaultDatetime = this.defaultTime;
    },
    setEmptyWorkingHours() {
      this.currentWorkingHours = [];
      this.defaultWorkingHours.forEach((day) => {
        this.currentWorkingHours.push({
          active: false,
          day_id: day.id,
          day_name: day.description,
          openTime: '',
          closeTime: '',
          validOpenTime: '',
          validCloseTime: '',
          open_hour: null,
          open_minute: null,
          close_hour: null,
          close_minute: null
        });
      });
    },
    getDateTime(hour, minute) {
      // ionic date time required ISO 8601 Datetime Format: YYYY-MM-DDTHH:mmZ
      // Ignore the date cause we are just use time picker
      const currentDatetime = new Date();
      currentDatetime.setHours(hour, minute, 0);
      return currentDatetime.toISOString();
    },
    onChooseDefaultTime(event) {
      this.prevDatetime = event.target.value;
      this.defaultTimeValid = this.checkValidCloseTime(true, this.defaultOpenTime, this.defaultCloseTime);

      this.currentWorkingHours.forEach((element) => {
        if (element.active) {
          element.openTime = this.defaultOpenTime;
          element.closeTime = this.defaultCloseTime;
        }
      });

      if (!this.prevDatetime) {
        event.target.value = this.defaultDatetime;
      }
    },
    onChooseTime(event, defaultTime) {
      // defaultTime can be defaultOpenTime or defaultCloseTime
      this.prevDatetime = event.target.value;
      if (!this.prevDatetime && !defaultTime) {
        event.target.value = this.defaultDatetime;
      }
    },
    onCancelDatetime(event) {
      event.target.value = this.prevDatetime;
    },
    getColorValidate(active, errMsg) {
      if (active) {
        return errMsg ? 'danger' : '';
      }
    },
    checkValidateForm() {
      // validate manually in this form
      this.currentWorkingHours.forEach((item) => {
        item.validOpenTime = this.checkValidOpenTime(item.active, item.openTime);
        item.validCloseTime = this.checkValidCloseTime(item.active, item.openTime, item.closeTime);
      });
      const filterValidHours = this.currentWorkingHours.filter(
        (item) => item.validOpenTime || item.validCloseTime
      );
      return filterValidHours.length === 0;
    },
    checkValidOpenTime(active, openTime) {
      return active && !openTime ? this.$t('check_valid_open_time') : '';
    },
    checkValidCloseTime(active, openTime, closeTime) {
      if (active) {
        return !closeTime
          ? this.$t('check_valid_open_time')
          : new Date(openTime) > new Date(closeTime)
          ? this.$t('errorDefaultTime')
          : '';
      }
      return '';
    },
    setOpenTime() {
      if (!this.openTimeRef) {
        this.openTimeRef = true;
        this.closeTimeRef = false;
      } else {
        this.openTimeRef = false;
      }
    },
    setCloseTime() {
      if (!this.closeTimeRef) {
        this.closeTimeRef = true;
        this.openTimeRef = false;
      } else {
        this.closeTimeRef = false;
      }
    },
    closeDefTime() {
      this.openTimeRef = false;
      this.closeTimeRef = false;
    },
    onChangeHourActive(workingHour) {
      workingHour.openTime = workingHour.active ? this.defaultOpenTime : '';
      workingHour.closeTime = workingHour.active ? this.defaultCloseTime : '';
      workingHour.validOpenTime = '';
      workingHour.validCloseTime = '';
      this.onChangeOpenCloseTime(workingHour);
    },
    onChangeOpenCloseTime(workingHour) {
      workingHour.validOpenTime = this.checkValidOpenTime(workingHour.active, workingHour.openTime);
      workingHour.validCloseTime = this.checkValidCloseTime(
        workingHour.active,
        workingHour.openTime,
        workingHour.closeTime
      );
    },

    saveHours() {
      if (this.checkValidateForm()) {
        const payload = [];
        this.currentWorkingHours.forEach((item) => {
          const openTime = new Date(item.openTime);
          const closeTime = new Date(item.closeTime);
          payload.push({
            ...pick(item, ['id', 'active', 'day_id']),
            open_hour: item.active ? openTime.getHours() : null,
            open_minute: item.active ? openTime.getMinutes() : null,
            close_hour: item.active ? closeTime.getHours() : null,
            close_minute: item.active ? closeTime.getMinutes() : null
          });
        });
        this.$emit('handleUpdateHour', payload, this.delivery_instruction);
      }
    },

    getDeliveryInstruction(deliveryInstructionVal) {
      this.delivery_instruction = deliveryInstructionVal;
    }
  }
});
</script>

<style src="./styles/ModalEditDeliveryTime.scss" lang="scss" scoped></style>
