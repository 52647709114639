<template>
  <div>
    <div
      class="image-default"
      :style="`background: ${theme.background}; color:${theme.color}`"
      v-if="isDefaultImage || isErrorImg"
    >
      {{ getInitialName(customer_name) }}
    </div>
    <td-image v-else :image="image" :imagePath="IMAGE_PATH.CUSTOMER" @errorLoadImg="errorLoadImg"></td-image>
  </div>
</template>

<script>
import { IMAGE_PATH } from '@/modules/sale/constants/index';
import { handleRandomBackground } from '@/modules/shared/utils';
export default {
  setup() {
    return {
      IMAGE_PATH
    };
  },
  data() {
    return {
      isDefaultImage: true,
      isErrorImg: false,
      theme: []
    };
  },
  props: ['image', 'customer_name'],
  async created() {
    await this.handleShowImage();
  },
  methods: {
    async handleShowImage() {
      if (!this.image || this.isErrorImg || this.isDefaultImage) {
        this.isDefaultImage = true;
        this.theme = handleRandomBackground();
      } else {
        this.isDefaultImage = false;
      }
    },
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    errorLoadImg() {
      this.isErrorImg = true;
    }
  },
  watch: {
    image() {
      this.handleShowImage();
    },
    isErrorImg() {
      this.handleShowImage();
    }
  }
};
</script>
<style scoped>
.image-default {
  width: 100%;
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 55px;
  font-weight: 500;
}
img {
  width: 100%;
  height: 224px;
}
</style>
