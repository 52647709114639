<template>
  <ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start" hide-back-button="true">
        <ion-back-button text="" @click="presentAlert" default-href="/"></ion-back-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
export default {
  props: ['title', 'customerDetails'],
  methods: {
    async presentAlert() {
      this.$emit('handleCloseModal');
    }
  }
};
</script>

<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>
