<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" @click="$router.push('/sale/customers')" default-href=""></ion-back-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <customer-detail @title="handleSetTitle" :details-key="detailsKey" />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import CustomerDetail from './newcomponents/CustomerDetail.vue';

export default defineComponent({
  name: 'CustomerDetailPage',
  components: {
    CustomerDetail
  },
  data() {
    return {
      title: '',
      detailsKey: `${new Date().getTime()}`
    };
  },

  methods: {
    handleSetTitle(event) {
      this.title = event;
    }
  },
  async ionViewDidEnter() {
    this.detailsKey = `${new Date().getTime()}`;
  }
});
</script>
<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>
<style scoped>
ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}
</style>
