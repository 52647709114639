import {
  DEFAULT_ZOOM_GGMAP,
  DEFAULT_ZOOM_GGMAP_FIRST_TIME,
  LAT_LNG_INDONESIA,
  LAT_LNG_MALAYSIA,
  LAT_LNG_SINGAPORE
} from '@/modules/shared/constants/common';

export const addressSchema = {
  country: (val) => (!val ? 'address_form.country.required' : true),
  state: (val) => (!val ? 'address_form.state.required' : true),
  city: (val) => (!val ? 'address_form.city.required' : true),
  postalCode: (val) => (!val ? 'address_form.postal_code.required' : true)
};
/* eslint-disable no-undef */
export const setUpMap = ({ defaultCenter, input, user, mapDiv, callback }) => {
  let center;
  let zoom;

  if (defaultCenter) {
    center = defaultCenter;
    zoom = DEFAULT_ZOOM_GGMAP;
  } else {
    center =
      user.country.id === 127
        ? LAT_LNG_MALAYSIA
        : user.country.id === 96
        ? LAT_LNG_INDONESIA
        : LAT_LNG_SINGAPORE;
    zoom = DEFAULT_ZOOM_GGMAP_FIRST_TIME;
  }
  const map = new google.maps.Map(mapDiv, {
    center,
    zoom
  });

  if (defaultCenter) {
    new google.maps.Marker({
      position: center,
      map
    });
  }

  const options = {
    componentRestrictions: { country: user.country.name },
    fields: ['formatted_address', 'address_components', 'geometry'],
    strictBounds: false,
    types: []
  };
  const autocomplete = new google.maps.places.Autocomplete(input, options);
  // Bind the map's bounds (viewport) property to the autocomplete object,
  // so that the autocomplete requests use the current map bounds for the
  // bounds option in the request.
  autocomplete.bindTo('bounds', map);
  const marker = new google.maps.Marker({
    map,
    anchorPoint: new google.maps.Point(0, -29)
  });

  autocomplete.addListener('place_changed', () => {
    marker.setVisible(false);
    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
    }
    map.setZoom(DEFAULT_ZOOM_GGMAP);
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);

    callback(place);
  });
};

export const getDataFromPlace = (place) => {
  const data = {
    country: '',
    state: '',
    city: '',
    postalCode: '',
    streetNumber: '',
    road: ''
  };
  data['latlng'] = place.geometry.location.toJSON();
  place.address_components.map((item) => {
    switch (true) {
      case item.types.includes('country'): {
        data['country'] = item.long_name;
        break;
      }
      case item.types.includes('political'): {
        data['state'] = item.long_name;
        data['city'] = item.long_name;
        break;
      }
      case item.types.includes('postal_code'): {
        data['postalCode'] = item.long_name;
        break;
      }
      case item.types.includes('street_number'): {
        data['streetNumber'] = item.long_name;
        break;
      }
      case item.types.includes('route'): {
        data['road'] = item.long_name;
        break;
      }
      default:
        return;
    }
  });

  if (!data['streetNumber'] && data['postalCode']) {
    data['streetNumber'] = place.formatted_address
      ? place.formatted_address.split(data['postalCode'])[0].replace(/^(.*)(,\s|,)$/g, '$1')
      : '';
  }

  if (data['streetNumber'] !== '') {
    data['road'] = data['streetNumber'] + ' ' + data['road'];
  }
  return data;
};
