<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="px-2">
        <ion-label slot="start" class="fs-3 fw-bold">{{ $t('select_business_profile') }}</ion-label>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!-- show-skeleton-loading -->
      <ion-item
        v-show="!isShow"
        v-for="(item, index) in 9"
        :key="index"
        lines="full"
        :detail="false"
        class="skeleton mt-2 px-3"
      >
        <ion-label>
          <ion-skeleton-text
            class="skeleton-child"
            :animated="true"
            style="width: 50%; height: 18px"
          ></ion-skeleton-text>
        </ion-label>
        <ion-skeleton-text
          class="avatar mt-2"
          style="width: 18px; height: 18px"
          :animated="true"
        ></ion-skeleton-text>
      </ion-item>
      <ion-list v-if="isShow" class="pt-2 mb-4">
        <ion-radio-group :value="defaultProfile" mode="md">
          <div v-for="index in businessProfile" :key="index.id">
            <ion-item lines="full" class="px-3 mb-2" @click="selectedProfile(index)">
              <ion-label color="dark">
                {{ index.name }}
              </ion-label>
              <ion-radio
                class="radio-btn"
                slot="end"
                :value="index.id"
                @ionFocus="selectedProfile(index)"
              ></ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
import { closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomerProfiles',
  setup() {},
  data() {
    return {
      closeOutline,
      isShow: false
    };
  },

  props: {
    businessProfile: {
      type: Object,
      default: null
    },
    defaultProfile: {
      type: Number,
      default: null
    }
  },

  emits: ['closeModal', 'selectProfile'],

  mounted() {
    this.checkBusinessProfile();
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
      this.isShow = false;
    },

    checkBusinessProfile() {
      if (this.businessProfile.length > 0) {
        this.isShow = true;
      }
    },

    selectedProfile(businessProfile) {
      this.$emit('selectProfile', businessProfile);
      this.closeModal();
    }
  },
  watch: {
    businessProfile() {
      if (this.businessProfile.length > 0) {
        this.isShow = true;
      }
    }
  }
});
</script>

<style scoped lang="scss">
.name {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}
ion-label {
  white-space: normal !important;
  font-weight: 400;
  line-height: 22px;
}

#row {
  height: 50px;
  font-size: 20px;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
.avatar {
  --border-radius: 100%;
}
.skeleton-child {
  --border-radius: 8px;
}
.skeleton {
  align-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.radio-btn {
  margin-right: -12px;
}
</style>
